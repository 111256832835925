<script setup lang="ts"></script>

<template>
  <div class="absolute flex min-h-screen w-full flex-col">
    <MeedoenHeader :show-mobile-menu="false" />

    <slot name="container">
      <Container class="flex grow flex-col pb-4 pt-10">
        <slot />
      </Container>
    </slot>
    <TairoPanels />

    <MeedoenFooter />

    <CookieConsent />
  </div>
</template>
